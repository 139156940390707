<section class="page-container vmg-webmaster-section">
  <div class="home-body-container">
    <div class="container vmg-webmaster">
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom: 15px">WHAT IS VMG WEBMASTER?</h1>
          <ul>
            <li>VMG Webmaster is software developed and owned by VMG Software. It has been created by the
              trade, for the trade, over the past 12 years.</li>
            <li>VMG WebMaster currently uploads over 45000 vehicles from hundreds of dealership customers
              across SA, to multiple dozens of different website destinations and aggregators.</li>
            <li>VMG Webmaster is a standalone software module that forms part of the VMG Dealership
              Management Software eco system.</li>
            <li>VMG Webmaster is an easy to use web management platform that helps your dealership
              streamline and centralise all your web advertising data in a single software environment and
              upload and remove vehicles seamlessly from multiple destinations at the click of a button.</li>
            <li>VMG Webmaster delivers the following primary services
              <ul>
                <li>Feed to SA’s leading advertising websites such as Cars.co.za, Gumtree, Surf4Cars and
                  many more. <a href="https://vmgsoftware.co.za/webmaster" target="_blank">Click here</a> to read more
                  <ul>
                    <li class="mb-0">Each active feed is billed at R260 (VAT Incl) per month</li>
                    <li class="mb-0">Excludes AutoTrader (we are ready when they are).</li>
                    <li class="mb-0">Feed to your dealer website. Conditions apply.</li>
                  </ul>
                </li>
                <li>
                  Dealership Website Development. <a href="https://vmgsoftware.co.za/webmaster" target="_blank">Click here</a> to see more.
                  <ul>
                    <li class="mb-0">WordPress templates starting from R 4 000 (VAT Incl)</li>
                    <li class="mb-0">Customised websites starting from R 12 000 (VAT Incl)</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
