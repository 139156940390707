<div class="navbar-nav">
  <a class="nav-item nav-link"
     [routerLink]="['/home']"
     (click)="collapseMenu()">Home</a>
  <a class="nav-item nav-link"
     [routerLink]="['/showroom']"
     (click)="collapseMenu()">Showroom</a>
  <a class="nav-item nav-link"
     [routerLink]="['/capitec-finance']"
     (click)="collapseMenu()">Capitec Finance Showroom</a>
</div>
